var d=(e,s={})=>{if(e&&e.length>0){let{width:i=500,height:n=500,orientation:a="portrait",format:l="A4"}=s,t=window.open("","",`height=${n}, width=${i}`);t&&(e.forEach((o,r)=>{t.document.write(`
          <html>
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Print Example - Page ${r+1}</title>
              <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet" as="style">
              <style>
                @media print {
                  @page {
                    size: ${i}px ${n}px ${a};
                  }
                }
              </style>
            </head>
            <body>
              ${o}
            </body>
          </html>
        `),r<e.length-1&&t.document.write('<div style="page-break-after: always;"></div>')}),setTimeout(()=>{t.document.close(),t.print()},1e3))}};export{d as a};

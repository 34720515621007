"use client";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import useSWRMutation from "swr/mutation";
// import { checkoutProcessMachine } from "@/components/checkout-process/machines/checkout.machine";
import { useMachine } from "@xstate/react";

import { SERVICES_URLS } from "@sajilni/config/constants";
import {
  Button,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "@/lib/ui";

import { ChevronDown } from "lucide-react";
import { type User, OrderStatus } from "database";

async function cancelOrder(url: string, { arg }: { arg: any }) {
  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...arg }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
  }
}

async function exportToExcel(url: string, { arg }: { arg: any }) {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({ ...arg }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    return data;
  } catch (e) {
    console.error(e);
  }
}

export const ReportActions = ({
  ids,
  data,
  session,
}: {
  ids: any[];
  data: any;
  session: { user: User };
}) => {
  const router = useRouter();
  const [url, setUrl] = useState(null);
  // const [state, send] = useMachine(checkoutProcessMachine(""));

  const { trigger, isMutating, error } = useSWRMutation(
    `${SERVICES_URLS.ORDER_APP}/api/order`,
    cancelOrder
  );

  const {
    data: excelData,
    trigger: exportToExcelTrigger,
    isMutating: isExportToExcelMutating,
  } = useSWRMutation(
    `${SERVICES_URLS.ORDER_APP}/api/export-order-sheet`,
    exportToExcel
  );

  useEffect(() => {
    if (excelData && excelData.url) {
      setUrl(excelData.url);
      !url && router.push(excelData.url);
      setUrl(null);
    }
  }, [excelData, url, router]);

  const exportReportToExcel = async () => {
    await exportToExcelTrigger({ data });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outlined" className="">
          Actions <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="flex flex-col items-start">
        <Button
          variant="solid"
          onClick={() => {
            trigger({ ids });
            //   ids.map((orderId) =>
            //     send({
            //       type: "CANCELED",
            //       status: OrderStatus.CANCELED,
            //       orderId: orderId,
            //       createdBy: session?.user?.name || "",
            //     })
            //   );
          }}
          disabled={isMutating || error}
        >
          Cancel order
        </Button>
        <Button
          variant="solid"
          onClick={() => exportReportToExcel()}
          disabled={isExportToExcelMutating}
        >
          Export to excel
        </Button>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

import React from 'react';
import moment from "moment";
import { FormTemplate, Order, OrderItem, Profile, Ticket, TicketTemplate } from "database/*";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  ShareBadge,
  ShareEvent,
  AddToCalendar,
} from "@/lib/ui";
import { useTranslation } from "@sajilni/localization";
import CalendarDownloadButton from './CalendarDownloadButton';
export interface SuccessOrder extends Order {
  profile?: Profile;
  orderItems: OrderItem &
  {
    ticket: Ticket & {
      formEntity: {
        formTemplate: FormTemplate | null;
      } | null;
      template: TicketTemplate;
    }; // Add the template property to the ticket object
  }[];
}
export const Actions = (
  {
    googleWallet,
    appleWallet,
    event,
    order,
  }: {
    googleWallet: (value: any) => void;
    appleWallet: (value: any) => void;
    event: any;
    order: SuccessOrder
  }

) => {
  const currentUrl = window.location.href;
  const updatedUrl = currentUrl.replace(/\/[^/]+$/, `/ticket-selection`);
  const { t } = useTranslation("sajilni2.0")
  return (
    <div className="grid-col-2 m-auto items-center justify-center gap-2 text-center sm:grid md:flex">
      <Dialog>
        <DialogTrigger>
          <Button variant={"outlined"} className={"whitespace-nowrap x-5 py-3 gap-2"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.666 7.91659V5.99992C16.666 5.0665 16.666 4.59979 16.4844 4.24327C16.3246 3.92967 16.0696 3.6747 15.756 3.51491C15.3995 3.33325 14.9328 3.33325 13.9993 3.33325H4.33268C3.39926 3.33325 2.93255 3.33325 2.57603 3.51491C2.26243 3.6747 2.00746 3.92966 1.84767 4.24327C1.66602 4.59979 1.66602 5.0665 1.66602 5.99992V13.9999C1.66602 14.9333 1.66602 15.4 1.84767 15.7566C2.00746 16.0702 2.26243 16.3251 2.57603 16.4849C2.93255 16.6666 3.39926 16.6666 4.33268 16.6666L13.9993 16.6666C14.9328 16.6666 15.3995 16.6666 15.756 16.4849C16.0696 16.3251 16.3246 16.0702 16.4844 15.7566C16.666 15.4 16.666 14.9333 16.666 13.9999V12.0833M12.4993 9.99992C12.4993 9.61271 12.4993 9.4191 12.5314 9.2581C12.6629 8.59695 13.1797 8.08012 13.8409 7.94861C14.0019 7.91659 14.1955 7.91659 14.5827 7.91659H16.2493C16.6366 7.91659 16.8302 7.91659 16.9912 7.94861C17.6523 8.08012 18.1691 8.59695 18.3007 9.2581C18.3327 9.4191 18.3327 9.61271 18.3327 9.99992C18.3327 10.3871 18.3327 10.5807 18.3007 10.7417C18.1691 11.4029 17.6523 11.9197 16.9912 12.0512C16.8302 12.0833 16.6366 12.0833 16.2493 12.0833H14.5827C14.1955 12.0833 14.0019 12.0833 13.8409 12.0512C13.1797 11.9197 12.6629 11.4029 12.5314 10.7417C12.4993 10.5807 12.4993 10.3871 12.4993 9.99992Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            {t("Add to google wallet")}
          </Button>
        </DialogTrigger>
        <DialogContent className={"!w-fit rounded-lg !py-2 !bg-gray-100"}>
          <div className='text-lg font-bold text-gray-900'>
            {t("Tickets")}
          </div>
          <div className="-mt-2">
            {order.orderItems.map((item: any, index: number) => {
              return (
                <div key={index} onClick={() => googleWallet({
                  eventName: event?.eventTranslations?.[0]?.name,
                  eventDescription: event?.eventTranslations?.[0]?.description,
                  orderItemId: item?.id,
                  ticketName: item?.ticket?.ticketTranslations?.[0]?.name,
                  eventStartDate: moment(event?.startDate).format("YYYY-MM-DDTHH:mm"),
                  eventImage: event?.image
                })} className="flex flex-row gap-2 items-center cursor-pointer">
                  <span className='font-semibold text-base'>{item?.ticket?.ticketTranslations?.[0]?.name}</span>
                  <span className='font-semibold text-xs'>#{item?.id}</span>
                </div>
              )
            })}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog>
        <DialogTrigger>
          <Button variant={"outlined"} className={"whitespace-nowrap x-5 py-3 gap-2"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.666 7.91659V5.99992C16.666 5.0665 16.666 4.59979 16.4844 4.24327C16.3246 3.92967 16.0696 3.6747 15.756 3.51491C15.3995 3.33325 14.9328 3.33325 13.9993 3.33325H4.33268C3.39926 3.33325 2.93255 3.33325 2.57603 3.51491C2.26243 3.6747 2.00746 3.92966 1.84767 4.24327C1.66602 4.59979 1.66602 5.0665 1.66602 5.99992V13.9999C1.66602 14.9333 1.66602 15.4 1.84767 15.7566C2.00746 16.0702 2.26243 16.3251 2.57603 16.4849C2.93255 16.6666 3.39926 16.6666 4.33268 16.6666L13.9993 16.6666C14.9328 16.6666 15.3995 16.6666 15.756 16.4849C16.0696 16.3251 16.3246 16.0702 16.4844 15.7566C16.666 15.4 16.666 14.9333 16.666 13.9999V12.0833M12.4993 9.99992C12.4993 9.61271 12.4993 9.4191 12.5314 9.2581C12.6629 8.59695 13.1797 8.08012 13.8409 7.94861C14.0019 7.91659 14.1955 7.91659 14.5827 7.91659H16.2493C16.6366 7.91659 16.8302 7.91659 16.9912 7.94861C17.6523 8.08012 18.1691 8.59695 18.3007 9.2581C18.3327 9.4191 18.3327 9.61271 18.3327 9.99992C18.3327 10.3871 18.3327 10.5807 18.3007 10.7417C18.1691 11.4029 17.6523 11.9197 16.9912 12.0512C16.8302 12.0833 16.6366 12.0833 16.2493 12.0833H14.5827C14.1955 12.0833 14.0019 12.0833 13.8409 12.0512C13.1797 11.9197 12.6629 11.4029 12.5314 10.7417C12.4993 10.5807 12.4993 10.3871 12.4993 9.99992Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            {t("Add to apple wallet")}
          </Button>
        </DialogTrigger>
        <DialogContent className={"!w-fit rounded-lg !py-2 !bg-gray-100"}>
          <div className='text-lg font-bold text-gray-900'>
            {t("Tickets")}
          </div>
          <div className="-mt-2">
            {order.orderItems.map((item: any, index: number) => {
              return (
                <div key={index} onClick={() => appleWallet({
                  eventName: event?.eventTranslations?.[0]?.name,
                  eventDescription: event?.eventTranslations?.[0]?.description,
                  orderItemId: item?.id,
                  ticketName: item?.ticket?.ticketTranslations?.[0]?.name,
                  eventStartDate: moment(event?.startDate).format("YYYY-MM-DD"),
                  eventStartTime: moment(event?.startDate).format("HH:mm A"),
                  eventImage: event?.image
                })} className="flex flex-row gap-2 items-center cursor-pointer">
                  <span className='font-semibold text-base'>{item?.ticket?.ticketTranslations?.[0]?.name}</span>
                  <span className='font-semibold text-xs'>#{item?.id}</span>
                </div>
              )
            })}
          </div>
        </DialogContent>
      </Dialog>

      <CalendarDownloadButton event={event} />
      <Dialog>
        <DialogTrigger>
          <Button
            variant="outlined"
            className="col-span-3 whitespace-nowrap x-5 py-3 gap-2"
            size="sm"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M7.15833 11.2584L12.85 14.5751M12.8417 5.42508L7.15833 8.74175M17.5 4.16675C17.5 5.54746 16.3807 6.66675 15 6.66675C13.6193 6.66675 12.5 5.54746 12.5 4.16675C12.5 2.78604 13.6193 1.66675 15 1.66675C16.3807 1.66675 17.5 2.78604 17.5 4.16675ZM7.5 10.0001C7.5 11.3808 6.38071 12.5001 5 12.5001C3.61929 12.5001 2.5 11.3808 2.5 10.0001C2.5 8.61937 3.61929 7.50008 5 7.50008C6.38071 7.50008 7.5 8.61937 7.5 10.0001ZM17.5 15.8334C17.5 17.2141 16.3807 18.3334 15 18.3334C13.6193 18.3334 12.5 17.2141 12.5 15.8334C12.5 14.4527 13.6193 13.3334 15 13.3334C16.3807 13.3334 17.5 14.4527 17.5 15.8334Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            {t("Share event on social media")}
          </Button>
        </DialogTrigger>
        <DialogContent className="!w-96 rounded-lg !bg-gray-100 !py-2">
          <div className='text-lg font-bold text-gray-900'>
            {t("Share Event")}
          </div>
          <ShareEvent url={updatedUrl} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
import React from 'react';
import { saveAs } from 'file-saver';
import { Button } from "@/lib/ui";
import moment from "moment";
import { CalendarPlus } from 'lucide-react';
import { useTranslation } from "@sajilni/localization";

const CalendarDownloadButton = ({ event }) => {
  console.log("event", event)
  // Initialize ics.js-like functionality
  const cal = {
    events: [],
    addEvent: function (summary, description, prodid, dtstart, dtend) {
      const event = `BEGIN:VEVENT
UID:${this.events.length}@website.com
CLASS:PUBLIC
DESCRIPTION:${description}
DTSTAMP;VALUE=DATE-TIME:${new Date().toISOString()}
DTSTART;VALUE=DATE-TIME:${dtstart}
DTEND;VALUE=DATE-TIME:${dtend}
LOCATION:${prodid}
SUMMARY;LANGUAGE=en-us:${summary}
TRANSP:TRANSPARENT
END:VEVENT`;
      this.events.push(event);
    },
    download: function (filename) {
      if (this.events.length < 1) return;
      const calendarData = `BEGIN:VCALENDAR
PRODID:Calendar
VERSION:2.0
${this.events.join('\n')}
END:VCALENDAR`;
      const blob = new Blob([calendarData], { type: 'text/calendar;charset=utf-8' });
      saveAs(blob, `${filename}.ics`);
    },
  };
  // Event data
  const newEvent = {
    SUMMARY: event?.eventTranslations?.[0]?.name,
    DESCRIPTION: event?.eventTranslations?.[0]?.description,
    PRODID: 'www.sajilni.com',
    DTSTART: moment(event?.startDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('YYYYMMDDTHHmmss'),
    DTEND: event?.endDate ? moment(event?.endDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('YYYYMMDDTHHmmss') : "",
  };
  // Add event to calendar
  cal.addEvent(newEvent.SUMMARY, newEvent.DESCRIPTION, newEvent.PRODID, newEvent.DTSTART, newEvent.DTEND);
  // Function to handle download button click
  const handleDownload = () => {
    cal.download("my_calendar");
  };
  const { t } = useTranslation("sajilni2.0")
  return (
    <Button
      variant="outlined"
      className="col-span-3 whitespace-nowrap x-5 py-3 gap-2"
      size="sm"
      onClick={handleDownload}
    >
      <CalendarPlus className='text-[#344054] w-5 h-5' />
      {t("Save to calendar")}
    </Button>
  );
};
export default CalendarDownloadButton;
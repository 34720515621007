import { removeCookie, setCookie } from "@sajilni/utils";
export const HandleGetUserData = async (token: string) => {
  try {
    const response: any = await fetch(
      `https://event-experience-new.vercel.app/api/auth/one-time-use-token?token=${token}`
    );
    if (response.ok) {
      const data = await response.json();
      if (data.userId) GetUserData(data.userId);
    } else {
      throw new Error(response.status);
    }
  } catch (error) {
    console.error("Error occurred:", error);
  }
};

export const GetUserData = async (userId: string) => {
  try {
    const useData: any = await fetch(
      `https://event-experience-new.vercel.app/api/users?id=${userId}`
    );
    if (useData.ok) {
      const data = await useData.json();
      setCookie("userId", userId);
    } else {
      throw new Error(useData.status);
    }
  } catch (error) {
    console.error("Error occurred:", error);
  }
};

export const SignOut = () => {
  removeCookie("userId");
};

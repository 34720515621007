"use client";

import Cookies from "js-cookie";

import { Countdown } from "@/lib/ui";
import { Order } from "database/*";
import { HelpCircle } from "lucide-react";

export const OrderForm = ({
  order,
  eventSlug,
}: {
  order: Order;
  eventSlug: string;
}) => {
  let initialTimeDifferenceInMinutes,
    initialMinute = 60,
    initialSeconds = 0;
  if (order?.expiryDate) {
    initialTimeDifferenceInMinutes =
      (new Date(order.expiryDate).getTime() - new Date().getTime()) /
      (1000 * 60);

    initialMinute = Math.floor(initialTimeDifferenceInMinutes);
    initialSeconds = Math.floor(
      (initialTimeDifferenceInMinutes -
        Math.floor(initialTimeDifferenceInMinutes)) *
        60
    );
  }

  return (
    <div className="flex flex-1 flex-col gap-3">
      <Countdown
        initialMinute={initialMinute}
        initialSeconds={initialSeconds}
        addToolTip={true}
        msg={"This is the order time left to finish your order"}
        trigger={<HelpCircle className="h-5 w-5 text-[#19AE67] " />}
        onComplete={() => {
          Cookies.remove("EventOrderIds");
          window.location.href = `/checkout/${eventSlug}/ticket-selection`;
        }}
      />
    </div>
  );
};

"use client";
import React from "react";
import { Button } from "@/lib/ui";
import { ArrowLeft, Calendar } from "lucide-react";
import { Event, EventTranslations } from "database";
import 'react-quill/dist/quill.snow.css';

export const TicketDescription = ({
  event,
}: {
  event: Event & {eventTranslations:EventTranslations[]};
}) => {
  return (
    <div className={`relative col-span-4 space-y-2  bg-white ${event?.eventTranslations?.[0]?.about && "sm:col-span-8"} p-4 shadow-sm`}>
      <div className="min-w-[60px] flex items-center cursor-pointer">
        <ArrowLeft className="h-4 w-4 text-gray-400" />
        <span className="text-[#98A2B3] text-lg font-bold pl-2">Back</span>
      </div>
      <h1 className="text-[#19AE67] text-4xl font-bold">{event?.eventTranslations?.[0]?.name}</h1>
      <div className="flex flex-row space-x-2 ">
        <Calendar className="text-[#19AE67] h-4 w-4" />
        <p className="text-sm font-normal text-gray-500">
          {`${new Date(event?.startDate || "").toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })} | ${new Date(event?.endDate || "").toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}`}
        </p> 
      </div>
      {event?.eventTranslations?.[0]?.about && (
        <div dangerouslySetInnerHTML={{ __html: event?.eventTranslations?.[0]?.about }} className="!mt-4 view ql-editor"/>
      )}
    </div>
  );
};

import React from "react";
import { useEffect } from "react";
import Barcode from "react-barcode";

export const BarcodeGenerator = ({
  value,
  height = 100,
  width,
  id,
  format = "CODE128",
}: {
  value: string;
  height?: number;
  width?: number;
  id: string;
  format?:
    | "CODE128"
    | "CODE39"
    | "EAN13"
    | "ITF14"
    | "MSI"
    | "pharmacode"
    | "codabar"
    | "upc";
}) => {
  //  craet ref for the barcode
  const barcodeRef = React.useRef(null);
  useEffect(() => {
    // get the width of the barcode from ref
    // @ts-ignore
    const barcode = barcodeRef?.current?.renderElementRef?.current;
    if (barcode) {
      barcode.style.width = `${width}px`;
    }
  }, [width]);

  return (
    <Barcode
      ref={barcodeRef}
      value={value}
      height={height}
      width={2}
      displayValue={false}
      format={format}
    />
  );
};

import { ImageIcon } from "lucide-react";
import {
  TicketItemProps,
  TicketImageProps,
} from "../../@types/ticketBuilderTypes";

const ImageTool = ({ id, props }: TicketItemProps) => {
  const { src, height, width, borderColor, borderWidth, angle } =
    props as TicketImageProps;

  const imageStyle = {
    border: `${borderWidth}px solid ${borderColor}`,
    transform: `rotate(${angle}deg)`,
    width: width,
    height: height,
  };
  return src ? (
    <div style={imageStyle}>
      <img src={src} height={height} width={width} alt={`ticket-img-${id}`} />
    </div>
  ) : (
    <div
      style={imageStyle}
      className="flex flex-col items-center justify-center gap-1  rounded-sm bg-stone-100 p-2 px-3"
    >
      <ImageIcon size={24} />
      <div>Image</div>
    </div>
  );
};

export default ImageTool;

import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal } from "lucide-react";

import { Button, Checkbox } from "@/lib/ui";
export type ColumnsType = {
  orderId: string;
  buyerName: string;
  buyerEmail: string;
  event: string;
  qty: number;
  currency: string;
  paymentType: string;
  status: string;
  eventId: string;
  orderFree: boolean;
  startDate: string;
  endDate: string;
  created: string;
};
export const columns: ColumnDef<ColumnsType>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value: boolean) =>
          table.toggleAllPageRowsSelected(!!value)
        }
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value: boolean) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "Order Id",
    // header: "Order Id",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Order Id
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "buyerName",
    header: "Buyer Name",
  },
  {
    accessorKey: "buyerEmail",
    header: "Buyer Email",
  },
  {
    accessorKey: "Event Id",
    header: "Event Id",
  },
  {
    accessorKey: "Event Name",
    header: "Event",
  },
  {
    accessorKey: "Qty",
    header: "Qty",
  },
  {
    accessorKey: "Currency",
    header: "Currency",
  },
  {
    accessorKey: "Total",
    header: "Total",
  },
  {
    accessorKey: "Status",
    header: "Status",
  },

  // {
  //   accessorKey: "isOrderFree",
  //   header: "Order Free",
  // },
  {
    accessorKey: "Start Date",
    header: "Start Date",
  },
  {
    accessorKey: "End Date",
    header: "End Date",
  },
  {
    accessorKey: "Created At",
    header: "Created",
  },
];

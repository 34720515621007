import { BarcodeGenerator } from "../../lib/react-barcode";
import {
  TicketItemProps,
  TicketQROrBarcodeProps,
} from "../../@types/ticketBuilderTypes";

const BarcodeTool = ({ id, props }: TicketItemProps) => {
  const { value, height, width, borderColor, borderWidth, angle, type } =
    props as TicketQROrBarcodeProps;
  return (
    <div
      className=" h-fit w-fit   "
      style={{
        transform: `rotate(${angle}deg)`,
        WebkitTransform: `rotate(${angle}deg)`,
        border: `${borderWidth}px solid ${borderColor}`,
      }}
    >
      {" "}
      {
        (
          <BarcodeGenerator
            id={id}
            value={value || "kll"}
            height={height}
            width={width}
            // @ts-ignore
            format={type}
          />
        ) as JSX.Element
      }
    </div>
  );
};

export default BarcodeTool;

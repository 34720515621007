"use client";
import React from "react";
import { useSearchParams, usePathname, useRouter } from "next/navigation";

import { cn } from "@sajilni/utils";

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Button,
  Checkbox,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Calendar,
} from "@/lib/ui";

const formSchema = z.object({
  event: z.string().optional(),
  eventId: z.string().optional(),
  orderId: z.string().optional(),
  isFree: z.boolean().optional(),
  status: z
    .enum(["NEW", "ON_GOING", "PAID", "REFUND", "EXPIRED", "CANCELED"])
    .optional(),
  startDate: z.date().optional(),
  endDate: z.date().optional(),
});

export const Filters = ({ type }: { type: string }) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams: any = useSearchParams();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      event: searchParams.get("event") || undefined,
      eventId: searchParams.get("eventId") || undefined,
      orderId: searchParams.get("orderId") || undefined,
      isFree: searchParams.get("isFree")
        ? JSON.parse(searchParams.get("isFree"))
        : false,
      status: searchParams.get("status") || undefined,
      startDate: searchParams.get("startDate")
        ? new Date(searchParams.get("startDate"))
        : undefined,
      endDate: searchParams.get("endDate")
        ? new Date(searchParams.get("endDate"))
        : undefined,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      Object.keys(values).forEach((key) => {
        if (
          values[key]?.length === 0 ||
          values[key] === undefined ||
          values[key] === false
        ) {
          delete values[key];
        }
      });
      let params;
      params = new URLSearchParams(searchParams);
      for (const property in values) {
        if (
          values.startDate ||
          values.endDate ||
          property == "startDate" ||
          property == "endDate"
        )
          params.set(property, format(values[property], "PP").toString());
        else params.set(property, values[property].toString());
      }
      router.push(pathname + "?" + params);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const resetForm = (values: z.infer<typeof formSchema>) => {
    try {
      form.reset((formValues) => ({
        ...formValues,
        event: undefined,
        eventId: undefined,
        orderId: undefined,
        status: undefined,
        startDate: undefined,
        endDate: undefined,
        isFree: false,
      }));
      let params = new URLSearchParams(searchParams);
      for (const property in values) {
        params.delete(property);
      }

      router.push(pathname + "?" + params);
    } catch (error) {
      console.error("Error resetting form:", error);
    }
  };
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        onReset={() => resetForm(form.getValues())}
        className="mb-3 space-y-8"
      >
        {type != "event" && (
          <>
            <FormField
              control={form.control}
              name="event"
              render={({ field }) => (
                <FormItem className="min-w-[45%]">
                  <FormLabel>Event</FormLabel>
                  <FormControl>
                    <Input placeholder="Event Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="eventId"
              render={({ field }) => (
                <FormItem className="min-w-[45%]">
                  <FormLabel>Event Id</FormLabel>
                  <FormControl>
                    <Input placeholder="Event Id" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}
        <div className="flex flex-wrap justify-between gap-2">
          <FormField
            control={form.control}
            name="orderId"
            render={({ field }) => (
              <FormItem className="min-w-[45%]">
                <FormLabel>Order Id</FormLabel>
                <FormControl>
                  <Input placeholder="Order Id" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem className="min-w-[45%]">
                <FormLabel>Status</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select status" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="z-50 bg-white">
                    <SelectItem value="NEW">NEW</SelectItem>
                    <SelectItem value="ON_GOING">ON_GOING</SelectItem>
                    <SelectItem value="PAID">PAID</SelectItem>
                    <SelectItem value="REFUND">REFUND</SelectItem>
                    <SelectItem value="EXPIRED">EXPIRED</SelectItem>
                    <SelectItem value="CANCELED">CANCELED</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-wrap justify-between gap-2">
          <FormField
            control={form.control}
            name="startDate"
            render={({ field }) => (
              <FormItem className="flex min-w-[45%] flex-col">
                <FormLabel>Event Start Date</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outlined"}
                        className={cn(
                          "pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="endDate"
            render={({ field }) => (
              <FormItem className="flex min-w-[45%] flex-col">
                <FormLabel>Event End Date</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outlined"}
                        className={cn(
                          "pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>

                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="isFree"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center gap-2">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel className="!mt-0">Is Free</FormLabel>
            </FormItem>
          )}
        />
        <div className="flex gap-2">
          <Button
            variant="solid"
            buttonColor="primary"
            type="submit"
            className="m-auto !flex"
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            type="reset"
            className="m-auto !flex"
            onClick={() => form.reset()}
          >
            Reset
          </Button>
        </div>
      </form>
    </Form>
  );
};

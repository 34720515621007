import { createContext, useState } from "react";
import { TicketProps, TicketItemTypes } from "../@types/ticketBuilderTypes";
import { defaultValuesByType } from "../data";
import { TicketTemplateVariables } from "database/*";
import { produce } from "immer";

interface TicketBuilderContextProps {
  ticketState: TicketProps[];
  setTicketState: (ticketState: TicketProps[]) => void;
  selectedItemId?: string | null;
  setSelectedItemId: (id: string | null) => void;
  ticketVariables: TicketTemplateVariables[];
  addTicketVariable: (variable: TicketTemplateVariables) => void;
  removeTicketVariable: (elementId: string) => void;
}

const defaultFormState: TicketProps[] = [
  {
    id: "container",
    type: TicketItemTypes.container,
    initPos: {
      x: 300,
      y: 200,
    },
    delta: {
      x: 300,
      y: 200,
    },
    props: defaultValuesByType[TicketItemTypes.container],
  },
  {
    id: "editorForm",
    type: TicketItemTypes.container,
    initPos: {
      x: 1000,
      y: 500,
    },
    delta: {
      x: 1000,
      y: 500,
    },
    props: defaultValuesByType[TicketItemTypes.container],
  },
];

export const TicketBuilderContext = createContext<TicketBuilderContextProps>({
  ticketState: defaultFormState,
  setTicketState: () => {},
  selectedItemId: null,
  setSelectedItemId: () => {},
  ticketVariables: [],
  addTicketVariable: () => {},
  removeTicketVariable: () => {},
});

export const TicketBuilderContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [ticketState, setTicketState] =
    useState<TicketProps[]>(defaultFormState);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [ticketVariables, setTicketVariables] = useState<
    TicketTemplateVariables[]
  >([]);

  const addTicketVariable = (variable: TicketTemplateVariables) => {
    if (ticketVariables.find((item) => item.elementId === variable.elementId)) {
      setTicketVariables(
        ticketVariables.map((item) =>
          item.elementId === variable.elementId ? variable : item
        )
      );
    } else setTicketVariables([...ticketVariables, variable]);
  };

  const removeTicketVariable = (elementId: string) => {
    setTicketVariables(
      ticketVariables.filter((item) => item.elementId !== elementId)
    );
  };

  return (
    <TicketBuilderContext.Provider
      value={{
        ticketState,
        setTicketState,
        selectedItemId,
        setSelectedItemId,
        ticketVariables,
        addTicketVariable,
        removeTicketVariable,
      }}
    >
      {children}
    </TicketBuilderContext.Provider>
  );
};

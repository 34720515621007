import { columns } from "./Columns";
import { Filters } from "./Filters";
import { OrderTable } from "./Table";
import type { ReportData } from "database/services";
import type { User } from "database/*";
export const OrderReport = ({
  data,
  type,
  session,
}: {
  data: ReportData;
  type: string;
  session: { user: User };
}) => {
  return (
    <div className="container mx-auto py-10">
      <Filters type={type} />
      {data.result?.length > 0 ? (
        <OrderTable
          columns={columns}
          data={data.result}
          pagination={data.pagination[0]}
          session={session}
        />
      ) : (
        <p>No Result</p>
      )}
    </div>
  );
};

import {
  TicketItemProps,
  TicketTextProps,
} from "../../@types/ticketBuilderTypes";

const TextTool = ({ id, props }: TicketItemProps) => {
  const {
    textValue,
    textAlign,
    color,
    backgroundColor,
    fontStyle,
    fontSize,
    fontFamily,
    letterSpacing,
    wordSpacing,
    angle,
    colorStroke,
  } = props as TicketTextProps;
  const className = `text-${textAlign} ${
    color ? `text-[${color}]` : "text-black"
  } ${backgroundColor ? `bg-[${backgroundColor}]` : "bg-transparent"} `;

  return (
    <div
      className={className}
      style={{
        backgroundColor: backgroundColor,
        color: color,
        textAlign: textAlign,
        fontWeight: fontStyle.includes("bold") ? "bold" : "normal",
        fontStyle: fontStyle.includes("italic") ? "italic" : "normal",
        textDecoration: fontStyle
          .filter((f) => f === "underline" || f === "line-through")
          .join(" " as string),
        fontSize: fontSize,
        letterSpacing: letterSpacing,
        wordSpacing: wordSpacing,
        fontFamily: fontFamily,
        transform: `rotate(${angle}deg)`,
        WebkitTransform: `rotate(${angle}deg)`,

        WebkitTextStrokeColor: colorStroke,
        WebkitTextStrokeWidth: "1px",
      }}
    >
      {" "}
      {textValue}
    </div>
  );
};

export default TextTool;

import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Workshop,
  // SwiperComponent,
  Badge,
  DialogHeader,
  DialogFooter,
} from "@/lib/ui";
export const Workshops = () => {
  const workshopItems = [
    {
      category: "User Experience",
      key: 101,
      items: [
        {
          key: 1,
          title: "Workshop Name is written here",
          price: 0,
          currency: "USD",
          date: new Date(2011, 0, 1, 0, 0, 0, 0),
          tag: "User Experience ",
          speaker: "Ohoud",
        },
        {
          key: 2,
          title: "Workshop Name is written here",
          price: 0,
          currency: "USD",
          date: new Date(2011, 0, 1, 0, 0, 0, 0),
          tag: "User Experience ",
          speaker: "Ohoud",
        },
      ],
    },
  ];
  const taps = [
    <Button
      key="1"
      variant={"outlined"}
      className="ui-max-w-[fit-content] cursor-pointer whitespace-nowrap"
    >
      All
    </Button>,
    <Button
      key="2"
      variant={"outlined"}
      className="ui-max-w-[fit-content] cursor-pointer whitespace-nowrap"
    >
      22 May
    </Button>,
    <Button
      key="3"
      variant={"outlined"}
      className="ui-max-w-[fit-content] cursor-pointer whitespace-nowrap"
    >
      23 May
    </Button>,
    <Button
      key="4"
      variant={"outlined"}
      className="ui-max-w-[fit-content] cursor-pointer whitespace-nowrap"
    >
      25 May
    </Button>,
    <Button
      key="5"
      variant={"outlined"}
      className="ui-max-w-[fit-content] cursor-pointer whitespace-nowrap"
    >
      25 May
    </Button>,
    <Button
      key="6"
      variant={"outlined"}
      className="ui-max-w-[fit-content] cursor-pointer whitespace-nowrap"
    >
      25 May
    </Button>,
  ];
  return (
    <Dialog>
      <DialogTrigger>
        <Button
          buttonColor="primary"
          variant="solid"
          type="submit"
          size="sm"
          className="whitespace-nowrap"
        >
          Book workshop
        </Button>
      </DialogTrigger>
      <DialogContent className="!bg-custom-blue container">
        {/* <SwiperComponent
          slides={taps}
          slidesPerView={5}
          spaceBetween={50}
          className={"!mt-3"}
          // className={"!w-[650px] min-w-[650px]"}
        /> */}
        <Workshop workshops={workshopItems} />
        <DialogFooter className="max-w-1/2 m-auto">
          <Button buttonColor="primary" variant="solid">
            Add to order
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

import { PropsWithChildren } from "react";
import { Resizable, ResizableProps } from "re-resizable";

const ResizableComponent = ({
  children,
  ...rest
}: PropsWithChildren<ResizableProps>) => {
  return <Resizable {...rest}>{children}</Resizable>;
};

export default ResizableComponent;

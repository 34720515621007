import { QRGenerator } from "../../lib/qrcode.react";
import {
  TicketItemProps,
  TicketQROrBarcodeProps,
} from "../../@types/ticketBuilderTypes";

const QRcodeTool = ({ id, props }: TicketItemProps) => {
  const { value, height, width, borderColor, borderWidth, angle } =
    props as TicketQROrBarcodeProps;
  return (
    <div
      className=" h-fit w-fit   p-2"
      style={{
        transform: `rotate(${angle}deg)`,
        WebkitTransform: `rotate(${angle}deg)`,
        border: `${borderWidth}px solid ${borderColor}`,
      }}
    >
      <QRGenerator id={id} value={value} size={width} />
    </div>
  );
};

export default QRcodeTool;

import { ElementType } from "react";
import { useDraggable, CSS } from "../../index";
import type { DraggableProps } from "../../types";

const defaultElement = "button";

const Draggable = <E extends ElementType = typeof defaultElement>({
  id,
  children,
  as,
  style,
  pos,
  ...rest
}: DraggableProps<E>) => {
  const Component = as ?? defaultElement;

  const { attributes, listeners, setNodeRef, transform, ...props } =
    useDraggable({
      id,
    });

  const ComponentStyle = {
    ...style,
    // Outputs `translate3d(x, y, 0)`
    transform: CSS.Translate.toString(
      pos
        ? {
            ...pos,
            scaleX: transform?.scaleX || 1,
            scaleY: transform?.scaleY || 1,
          }
        : transform
    ),
  };

  return (
    <Component
      {...rest}
      ref={setNodeRef}
      style={ComponentStyle}
      suppressHydrationWarning={true}
      {...listeners}
      {...attributes}
    >
      <div>{children}</div>
    </Component>
  );
};

export default Draggable;

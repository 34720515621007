import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import {
  Button,
  Input,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Checkbox,
  TicketGroup,
  ExtraProduct,
  Dialog,
  DialogContent,
  DialogTrigger,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/lib/ui";
import { Workshops } from "./Workshops";

export const TicketForm = ({ result }): JSX.Element => {
  const [extraProduct, setExtraProduct] = useState<boolean>(false);
  const form = useForm();
  const schema = yup
    .object({
      username: yup.string().required(),
      email: yup.string().required(),
      phoneNumber: yup
        .string()
        .test("valid-mobile-number", "Invalid mobile number", (value) => {
          if (!value) return true;
          const phoneNumber = parsePhoneNumberFromString(value, "JO");
          return phoneNumber ? phoneNumber.isValid() : false;
        })
        .required("Mobile number is required"),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;
  const { register, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (values: FormData) => console.log(values);
  const ExtraProductItems = [
    {
      category: "Food",
      subItems: [
        {
          title: "Mix Grill 2",
          price: 0,
          currency: "USD",
          image:
            "https://images.unsplash.com/photo-1572635196237-14b3f281503f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=580&q=80",
        },
        {
          title: "Mix Grill 5",
          price: 0,
          currency: "USD",
          image:
            "https://images.unsplash.com/photo-1572635196237-14b3f281503f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=580&q=80",
        },
      ],
    },
    {
      category: "Accessories",
      subItems: [
        {
          title: "Event Poster",
          price: 10,
          currency: "USD",
          image:
            "https://images.unsplash.com/photo-1572635196237-14b3f281503f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=580&q=80",
        },
      ],
    },
  ];

  return (
    <div className="flex flex-1 flex-col gap-2 ">
      <h2 className="font-semibold">My Tickets</h2>
      {result?.order_items.map((orderItem) =>
        orderItem?.price.map((item) =>
          item?.items.map((subItem, index) => (
            <>
              <Card>
                <TicketGroup
                  group={subItem.group}
                  title={subItem.class}
                  price={subItem.price}
                  ticketCurrency={result.currency}
                  index={index}
                />
              </Card>
              <Card>
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <>
                      <CardHeader>
                        <h3>Ticket details</h3>
                      </CardHeader>
                      <CardContent>
                        <FormField
                          control={form.control}
                          name="username"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Input
                                  // type={"text"}
                                  placeholder="Full Name"
                                  {...register("username")}
                                />
                              </FormControl>
                              <FormControl>
                                <Input
                                  type={"text"}
                                  placeholder="Email"
                                  {...register("email")}
                                />
                              </FormControl>
                              <FormControl>
                                <Input
                                  type={"number"}
                                  placeholder="Phone Number"
                                  {...register("phoneNumber")}
                                />
                              </FormControl>
                              <FormControl>
                                <div className="flex gap-1">
                                  <Checkbox checked={field.value} />
                                  <FormLabel>
                                    use this information for all tickets
                                  </FormLabel>
                                </div>
                              </FormControl>

                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </CardContent>
                      <CardFooter>
                        <div className="flex justify-center gap-3">
                          <Workshops />
                          <Dialog>
                            <DialogTrigger>
                              <Button
                                variant={"outlined"}
                                size={"sm"}
                                className={"whitespace-nowrap"}
                                onClick={() => setExtraProduct(true)}
                              >
                                Add extra product
                              </Button>
                            </DialogTrigger>
                            <DialogContent
                              className={"!bg-custom-blue !w-[80%] !pb-8"}
                            >
                              <ExtraProduct items={ExtraProductItems} />
                            </DialogContent>
                          </Dialog>
                        </div>
                      </CardFooter>
                    </>
                  </form>
                </Form>
              </Card>
            </>
          ))
        )
      )}
    </div>
  );
};

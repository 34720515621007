import {
  TicketQROrBarcodeProps,
  TicketContainerProps,
  TicketImageProps,
  TicketSquareProps,
  TicketTextProps,
} from "../../@types";
import {
  AlignCenter,
  AlignJustify,
  AlignRight,
  AlignLeft,
  Bold,
  Italic,
  Strikethrough,
  Underline,
} from "lucide-react";

export const textDefaultValues: TicketTextProps = {
  textValue: "TEXT",
  format: "normal",
  fontFamily: "Arial",
  fontSize: 24,
  fontWeight: "normal",
  fontStyle: [],
  textAlign: "left",
  color: "#000000",
  backgroundColor: "#ffffff",
  colorStroke: "#000000",
  letterSpacing: 0,
  wordSpacing: 0,
  angle: 0,
};

export const squareDefaultValues: TicketSquareProps = {
  width: 100,
  height: 100,
  backgroundColor: "#7ECA26",
  borderWidth: 0,
  borderColor: "#000000",
  angle: 0,
};

export const imageDefaultValues: TicketImageProps = {
  width: 100,
  height: 100,
  src: "",
  angle: 0,
  borderColor: "#000000",
  borderWidth: 0,
};

export const qrDefaultValues: TicketQROrBarcodeProps = {
  width: 100,
  height: 100,
  value: "",
  borderWidth: 0,
  borderColor: "#000000",
  type: "qr",
  angle: 0,
};

export const containerDefaultValues: TicketContainerProps = {
  width: 661.44,
  height: 384,
  backgroundSrc: "",
  layoutOrientation: "landscape",
  isDefault: false,
  POS: false,
  layoutFormat: "Custom",
};

export const barcodeDefaultValues: TicketQROrBarcodeProps = {
  width: 100,
  height: 100,
  value: "",
  borderWidth: 0,
  borderColor: "#000000",
  type: "CODE128",
  angle: 0,
};

export const defaultValuesByType = {
  text: textDefaultValues,
  square: squareDefaultValues,
  image: imageDefaultValues,
  qr: qrDefaultValues,
  container: containerDefaultValues,
  barcode: barcodeDefaultValues,
};

export const textAlignOptions = {
  left: AlignLeft,
  center: AlignCenter,
  right: AlignRight,
  justify: AlignJustify,
};

export type textAlignment = "left" | "center" | "right" | "justify";

export const textFontStyle = {
  bold: Bold,
  italic: Italic,
  underline: Underline,
  "line-through": Strikethrough,
};

export type textFontStyleType =
  | "bold"
  | "italic"
  | "underline"
  | "line-through";
export const textFormatOptions: { [key: string]: string[] } = {
  "Date/Time/Date-Time": [
    "dd.MM.yyyy, HH:mm",
    "EEEE, MMMM dd, yyyy, hh:mm a '('zzz')'",
    "EEE, MMM d, ''yy",
    "yyyy",
    "short",
    "medium",
    "long",
    "full",
  ],
  Number: [
    "number",
    "currency",
    "percent",
    "0",
    "0.#",
    "0.##",
    "0.###",
    "0.####",
    ",##0.00",
  ],
  String: ["The very first word capitalized", "All words capitalized"],
};

export const barcodeTypesOptions = [
  "CODE128",
  "CODE39",
  "EAN13",
  "ITF14",
  "MSI",
  "pharmacode",
  "codabar",
  "upc",
];

export const layoutOrientationOptions = ["portrait", "landscape"];

export const layoutFormatOptions = ["Custom", "A4", "A5", "A6", "A7", "A8"];

export const layoutFormatSizes = {
  Custom: {
    width: 661.44,
    height: 384,
  },
  A4: {
    width: 1122.52,
    height: 793.701,
  },
  A5: {
    width: 793.701,
    height: 559.37,
  },
  A6: {
    width: 559.37,
    height: 396.85,
  },
  A7: {
    width: 396.85,
    height: 279.685,
  },
  A8: {
    width: 279.685,
    height: 196.85,
  },
};

export const fontCheck = [
  // Windows 10
  "Arial",
  "Arial Black",
  "Bahnschrift",
  "Calibri",
  "Cambria",
  "Cambria Math",
  "Candara",
  "Comic Sans MS",
  "Consolas",
  "Constantia",
  "Corbel",
  "Courier New",
  "Ebrima",
  "Franklin Gothic Medium",
  "Gabriola",
  "Gadugi",
  "Georgia",
  "HoloLens MDL2 Assets",
  "Impact",
  "Ink Free",
  "Javanese Text",
  "Leelawadee UI",
  "Lucida Console",
  "Lucida Sans Unicode",
  "Malgun Gothic",
  "Marlett",
  "Microsoft Himalaya",
  "Microsoft JhengHei",
  "Microsoft New Tai Lue",
  "Microsoft PhagsPa",
  "Microsoft Sans Serif",
  "Microsoft Tai Le",
  "Microsoft YaHei",
  "Microsoft Yi Baiti",
  "MingLiU-ExtB",
  "Mongolian Baiti",
  "MS Gothic",
  "MV Boli",
  "Myanmar Text",
  "Nirmala UI",
  "Palatino Linotype",
  "Segoe MDL2 Assets",
  "Segoe Print",
  "Segoe Script",
  "Segoe UI",
  "Segoe UI Historic",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "SimSun",
  "Sitka",
  "Sylfaen",
  "Symbol",
  "Tahoma",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
  "Webdings",
  "Wingdings",
  "Yu Gothic",
  // macOS
  "American Typewriter",
  "Andale Mono",
  "Arial Narrow",
  "Arial Rounded MT Bold",
  "Arial Unicode MS",
  "Avenir",
  "Avenir Next",
  "Avenir Next Condensed",
  "Baskerville",
  "Big Caslon",
  "Bodoni 72",
  "Bodoni 72 Oldstyle",
  "Bodoni 72 Smallcaps",
  "Bradley Hand",
  "Brush Script MT",
  "Chalkboard",
  "Chalkboard SE",
  "Chalkduster",
  "Charter",
  "Cochin",
  "Comic Sans MS",
  "Copperplate",
  "Courier",
  "Courier New",
  "Didot",
  "DIN Alternate",
  "DIN Condensed",
  "Futura",
  "Geneva",
  "Georgia",
  "Gill Sans",
  "Helvetica",
  "Helvetica Neue",
  "Herculanum",
  "Hoefler Text",
  "Impact",
  "Lucida Grande",
  "Luminari",
  "Marker Felt",
  "Menlo",
  "Microsoft Sans Serif",
  "Monaco",
  "Noteworthy",
  "Optima",
  "Palatino",
  "Papyrus",
  "Phosphate",
  "Rockwell",
  "Savoye LET",
  "SignPainter",
  "Skia",
  "Snell Roundhand",
  "Tahoma",
  "Times",
  "Times New Roman",
  "Trattatello",
  "Trebuchet MS",
  "Verdana",
  "Zapfino",
].sort();

"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { getCookie } from "@sajilni/utils";
import { HandleGetUserData, SignOut } from "../../utils/Auth";
import { Navbar } from "@/lib/ui";
import { SERVICES_URLS } from "@sajilni/config/constants";

const logo = (
  <Image
    className="h-8 w-auto"
    src="https://www.sajilni.com/assets/resources/img/logo.png"
    alt="Sajilni"
    width={100}
    height={100}
    style={{ objectFit: "contain", objectPosition: "left" }}
    priority={true}
  />
);
export const MainNavbar = () => {
  const [loginURL, setLoginURL] = useState<string>("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setLoginURL(
      `${SERVICES_URLS.OLD_AUTH_APP}/signin?redirectUrl=${window.location.href}`
    );
    const userId = getCookie("userId");
    if (userId) setIsLoggedIn(true);
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const otp = params?.get("otp");
    if (otp) {
      HandleGetUserData(otp);
    }
  }, []);

  return (
    <Navbar
      logo={logo}
      loginURL={loginURL}
      SignOut={SignOut}
      isLoggedIn={isLoggedIn}
    />
  );
};

"use client";
import "react-multi-carousel/lib/styles.css";
import Carousel, { type CarouselProps } from "react-multi-carousel";
import { cn } from "@sajilni/utils";
type Responsive = {
  desktopItemsNumber: number;
  tabletItemsNumber: number;
  mobileItemsNumber: number;
};

interface swipeProps extends Omit<CarouselProps, "responsive"> {
  slideClassName?: string;
  slides: React.ReactNode[];
  responsive?: Responsive;
}
export const Swipe = ({
  slides = [],
  responsive = {
    desktopItemsNumber: 1,
    tabletItemsNumber: 1,
    mobileItemsNumber: 1,
  },
  className = "",
  centerMode = false,
  arrows = true,
  additionalTransfrom = 0,
  autoPlaySpeed = 30,
  containerClass = "",
  customLeftArrow = null,
  customRightArrow = null,
  customButtonGroup = null,
  customDot = null,
  dotListClass = "",
  draggable = false,
  focusOnSelect = false,
  infinite = true,
  itemClass = "",
  keyBoardControl = true,
  minimumTouchDrag = 80,
  pauseOnHover = true,
  renderArrowsWhenDisabled = false,
  renderButtonGroupOutside = false,
  renderDotsOutside = false,
  rewind = false,
  rewindWithAnimation = false,
  rtl = false,
  shouldResetAutoplay = true,
  showDots = false,
  sliderClass = "",
  slidesToSlide = 1,
  swipeable = true,
  slideClassName = "",
}: swipeProps) => {
  return (
    <Carousel
      additionalTransfrom={additionalTransfrom}
      arrows={arrows}
      autoPlaySpeed={autoPlaySpeed}
      centerMode={centerMode}
      className={className}
      containerClass={cn("container", containerClass)}
      customLeftArrow={customLeftArrow}
      customRightArrow={customRightArrow}
      customButtonGroup={customButtonGroup}
      customDot={customDot}
      dotListClass={dotListClass}
      draggable={draggable}
      focusOnSelect={focusOnSelect}
      infinite={infinite}
      itemClass={itemClass}
      keyBoardControl={keyBoardControl}
      minimumTouchDrag={minimumTouchDrag}
      pauseOnHover={pauseOnHover}
      renderArrowsWhenDisabled={renderArrowsWhenDisabled}
      renderButtonGroupOutside={renderButtonGroupOutside}
      renderDotsOutside={renderDotsOutside}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: responsive.desktopItemsNumber,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: responsive.mobileItemsNumber,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: responsive.tabletItemsNumber,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={rewind}
      rewindWithAnimation={rewindWithAnimation}
      rtl={rtl}
      shouldResetAutoplay={shouldResetAutoplay}
      showDots={showDots}
      sliderClass={sliderClass}
      slidesToSlide={slidesToSlide}
      swipeable={swipeable}
    >
      {slides.map((slide, index) => (
        <div key={index} className={slideClassName}>
          {slide}
        </div>
      ))}
    </Carousel>
  );
};

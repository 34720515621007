import QRCode from "qrcode.react";
export const QRGenerator = ({
  value,
  id,
  size = 100,
  className,
}: {
  value: string;
  id: string;
  size?: number;
  className?: string;
}) => {
  return (
    <QRCode
      id={id}
      value={value}
      size={size}
      className={className}
      level={"H"}
    />
  );
};

import { createMachine, assign } from "xstate";
import { createActorContext } from "@xstate/react";

export const TicketSelectionMachine = createMachine({
  predictableActionArguments: true,
  id: "selectedTicket",
  initial: "idle",
  context: {
    selectedTicket: {
      id: "",
      name: "",
      image: "",
      quantity: 0,
      price: 0,
    },
  },
  states: {
    idle: {
      on: {
        SELECT: {
          actions: assign({
            selectedTicket: (context, event: any) => event.ticket,
          }),
        },
        SUBMIT: "success",
      },
    },
    success: {
      type: "final",
    },
  },
});

export const SelectedTicketReactContext = createActorContext(
  TicketSelectionMachine
);

import axios from "axios";
import { SERVICES_URLS } from "@sajilni/config/constants";

const api = axios.create({
  baseURL: "https://www.ticket-jfa.jo",
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiSMS = axios.create({
  baseURL: "https://el.cloud.unifonic.com",
  headers: {
    "Content-Type": "application/json",
  },
});

export const baseApi = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiTwilio = axios.create({
  baseURL: "https://content.twilio.com/v1",
  headers: {
    "Content-Type": "application/json",
  },
  auth: {
    username: "AC77f1892495f7c4c7e55ce967f60c930b",
    password: "b8e745ba39a4fe5a6ac589cc4a8ed448",
  },
});
export const sajilniApi = axios.create({
  baseURL: `${SERVICES_URLS.MAIN_APP}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;

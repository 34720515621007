export const getRandomStringId = (existingIds: string[]): string => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const idLength = 10;
  let randomId = "";

  // Generate a random ID
  for (let i = 0; i < idLength; i++) {
    randomId += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }

  // Check if the generated ID already exists in the array
  while (existingIds.includes(randomId)) {
    // Generate a new random ID
    randomId = "";
    for (let i = 0; i < idLength; i++) {
      randomId += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
  }

  return randomId;
};

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Button } from "@/lib/ui";

interface FormValues {
  currency: string;
}
const currencyOptions = [
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "JOD", label: "JOD" },
];
export const CurrencyComponent = () => {
  const handleSubmit = async (values: FormValues) => {};

  const handleCurrencySelect = (currency: string) => {};

  const validationSchema = Yup.object().shape({
    currency: Yup.string().required("Currency is required"),
  });

  const initialValues: FormValues = {
    currency: "JOD",
  };

  return (
    <main>
      <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 ">
        <h3 className="bg-g group flex gap-x-3 rounded-md p-2 px-3 pt-4 text-lg font-semibold leading-6 text-indigo-600">
          Select your default currency:
        </h3>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnSubmit={true}
          validateOnBlur={false}
        >
          {({ errors, isSubmitting, handleSubmit, setFieldValue }) => (
            <Form className="m-4 flex flex-col" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-2 py-4">
                <div className="flex flex-col">
                  <label htmlFor="currency" className="p-2">
                    Currency:
                  </label>
                  <Field
                    as="select"
                    id="currency"
                    name="currency"
                    // value={values.currency}
                    // values={state.context.selectedCurrency}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      handleCurrencySelect(event.target.value);
                      setFieldValue("currency", event.target.value);
                    }}
                    className="ml-2 flex h-10 items-center justify-center gap-0.5 rounded-3xl border border-gray-200 shadow-sm focus:border-gray-400 focus:outline-none"
                  >
                    <option value="" disabled>
                      Select a currency
                    </option>
                    {currencyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  {errors.currency && (
                    <div className="px-4 text-xs text-red-500">
                      {errors.currency}
                    </div>
                    // <ErrorMessage
                    //   name="currency"
                    //   component="div"
                    //   className="text-xs text-red-500 px-4"
                    // />
                  )}
                </div>
              </div>
              <Button
                type="submit"
                size="lg"
                className="m-auto h-10 w-fit p-4"
                disabled={isSubmitting}
              >
                save
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  );
};

"use client";
import React, { useEffect, useState } from "react";
import type { LineItem } from "database";
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  Button,
  TicketCounter,
  Sale,
} from "@/lib/ui";
export { Price as default } from "@/lib/ui";
import { Suspense, lazy } from "react";
import { useTranslation } from '@sajilni/localization'
const Price = React.lazy(() =>
  import("@/lib/ui").then((module) => ({ default: module.Price }))
);
export type selectedItemsType = {
  ticketId: string;
  quantity: number;
};
export const Reservations = ({
  data,
  orderItems,
  selectedItems,
  setSelectedItems,
  InSale = false,
  ticketIdFromParams,
}: {
  data: any; // TicketInfoType;
  selectedItems: selectedItemsType[];
  setSelectedItems: React.Dispatch<React.SetStateAction<selectedItemsType[]>>;
  InSale?: boolean;
  ticketIdFromParams?: string | null;
  orderItems: LineItem[];
}) => {
  const { t } = useTranslation("sajilni2.0")
  const { ticket, ticketQuantity } = data;
  const [getTicket, setGetTicket] = useState<boolean>(false);
  const [ticketStatus, setTicketState] = useState<
    "SOLD OUT" | "RESERVED" | "AVAILABLE" | "Loading..."
  >("Loading...");

  let updatedSelectedItems = [...selectedItems];
  useEffect(() => {
    if (orderItems?.length) {
      for (let i = 0; i < orderItems.length; i++) {
        // Check if the order item with the same id already exists in selectedItems
        const existingItemIndex = updatedSelectedItems.findIndex(
          (item) => item.ticketId === orderItems[i].id
        );

        if (existingItemIndex !== -1) {
          // If the item exists, update its quantity
          updatedSelectedItems[existingItemIndex].quantity =
            orderItems[i].quantity;
        } else {
          // If the item doesn't exist, add it to the updated selected items
          updatedSelectedItems.push({
            quantity: orderItems[i].quantity,
            ticketId: orderItems[i].id,
          });
          setSelectedItems(updatedSelectedItems);
        }

        // Set the getTicket state to true for the corresponding ticket
        if (ticket.id === orderItems[i].id) {
          setGetTicket(true);
        }
      }
    }
  }, [orderItems, ticketStatus]);
  useEffect(() => {
    if (
      ticketIdFromParams &&
      ticketIdFromParams == ticket.id &&
      ticketStatus === "AVAILABLE"
    ) {
      updatedSelectedItems.push({
        quantity: ticket.soldTogether || 1,
        ticketId: ticketIdFromParams,
      });
      setGetTicket(true);

      setSelectedItems(updatedSelectedItems);
    }
  }, [ticketIdFromParams, ticketStatus]);

  useEffect(() => {
    if (ticketQuantity) {
      if (data.quantity == ticketQuantity.boughtTicket)
        setTicketState("SOLD OUT");
      else if (ticketQuantity.availableTicket === 0) setTicketState("RESERVED");
      else if (ticketQuantity.availableTicket) setTicketState("AVAILABLE");
    }
  }, [ticketQuantity, data]);

  const determineTicketQuantity = (
    quantity?: number,
    soldTogether?: number
  ) => {
    return quantity ? quantity : soldTogether ? soldTogether : 1;
  };

  const handleDecrease = async (quantity: number) => {
    if (
      (ticket.minAmount && quantity > ticket.minAmount) ||
      (!ticket.minAmount && quantity > 1)
    ) {
      const updatedLineItems = selectedItems.map((item) => {
        if (
          item.ticketId === ticket.id &&
          ((ticket.minAmount && item.quantity >= ticket.minAmount) ||
            !ticket.minAmount)
        ) {
          return {
            ...item,
            quantity:
              item.quantity - determineTicketQuantity(ticket.soldTogether),
          };
        }
        return item;
      });

      setSelectedItems(updatedLineItems);
    } else {
      const updatedLineItems = selectedItems.filter(
        (item) => item.ticketId !== ticket.id
      );
      setSelectedItems(updatedLineItems);
      setGetTicket(false); // Reset the getTicket state
    }
  };

  const handleIncrease = async (quantity: number) => {
    const updatedLineItems = selectedItems.map((item) => {
      if (
        item.ticketId === ticket.id &&
        (!ticket.maxAmount || item.quantity < ticket.maxAmount)
      ) {
        return {
          ...item,
          quantity:
            item.quantity + determineTicketQuantity(ticket.soldTogether),
        };
      }
      return item;
    });

    setSelectedItems(updatedLineItems);
  };

  const handleGetTicket = async () => {
    setGetTicket(true);
    setSelectedItems([
      ...selectedItems,
      {
        ticketId: ticket.id,
        quantity: determineTicketQuantity(
          ticket.minAmount,
          ticket.soldTogether
        ),
      },
    ]);
  };

  return (
    <div
      className="flex flex-col justify-between gap-2 text-start"
      id={ticket.id}
    >
      {/* {
        saleStatus === "In progress" &&
        data.ticket.published === true && ( */}
      <Card className="space-y-2 !border-[#19AE67] !p-4">
        <CardHeader className="!p-0">
          <CardTitle className="!text-lg !font-bold !text-gray-950">
            {ticket?.name || ""}
          </CardTitle>
          {ticket.showTicketDescription && (
            <CardDescription className="mt-1 !p-0">
              {ticket?.description || ""}
            </CardDescription>
          )}
        </CardHeader>
        <CardFooter className="justify-between gap-2 !p-0">
          <div className="flex flex-col gap-2">
            <Suspense fallback={<div>Loading...</div>}>
              <Price
                price={ticket?.price || 0}
                currency={ticket?.currency || ""}
                className="!text-[#19AE67]"
              />
            </Suspense>
            {InSale &&
              ticket?.saleEndDate &&
              new Date(ticket.saleEndDate) > new Date() ? (
              <Sale saleEndDate={ticket.saleEndDate} />
            ) : (
              <></>
            )}
          </div>
          {ticketStatus === "AVAILABLE" ? (
            <div className="min-h-[36px]">
              {getTicket ? (
                <>
                  <TicketCounter
                    handleDecrease={handleDecrease}
                    handleIncrease={handleIncrease}
                    quantity={
                      selectedItems.find((item) => item.ticketId === ticket.id)
                        ?.quantity as number
                    }
                  />
                </>
              ) : (
                <Button
                  size="sm"
                  onClick={async () => await handleGetTicket()}
                  className=" whitespace-nowrap !rounded-lg !bg-[#19AE67] text-sm text-white"
                >
                  {ticket?.buyButtonText || t("Get Ticket")}
                </Button>
              )}
            </div>
          ) : (
            <Button variant="outlined" size="sm" disabled={true}>
              {ticketStatus}
            </Button>
          )}
        </CardFooter>
      </Card>
      {/* )} */}
    </div>
  );
};

import React, { useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { useRouter } from "next/navigation";

import { Spinner } from "../Shared/Spinner";
import { Button, Input } from "@/lib/ui";

type Data = {};
interface FormValues {
  region: string;
  sale_tax_rate: number | null;
}
const initialValues: FormValues = {
  region: "",
  sale_tax_rate: null,
};
export const TaxRegionForm = () => {
  const router = useRouter();

  const [result, setResult] = useState<Data | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSubmit = async (values: FormValues) => {
    const data: Data = {
      region: values.region,
      sale_tax_rate: values.sale_tax_rate,
    };

    const res = await fetch("/api/tax-region", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await res.json();
    router.push("/tax-region");
  };

  const validationSchema = Yup.object().shape({
    region: Yup.string().required("Region is required"),
    sale_tax_rate: Yup.string().required("Tax Rate is required"),
  });
  return (
    <main className="">
      <div className="m-auto flex flex-col gap-2 py-5">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 ">
          <h3 className="bg-g group flex gap-x-3 rounded-md p-2 px-3 pt-4 text-lg font-semibold leading-6 text-indigo-600 ">
            Add Tax Region
          </h3>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnSubmit={true}
            validateOnBlur={false}
          >
            {({ handleSubmit, errors, isSubmitting, handleChange, values }) => (
              <Form className=" m-4 flex flex-col" onSubmit={handleSubmit}>
                <div className="flex flex-col gap-2 py-4">
                  <div className="flex flex-col">
                    <Input
                      id="region"
                      name="region"
                      onChange={handleChange}
                      value={values.region}
                    />

                    {errors.region ? (
                      <></>
                    ) : (
                      // <ErrorMessage
                      //   name="region"
                      //   component="div"
                      //   className="text-xs text-red-500"
                      // />
                      ""
                    )}
                  </div>

                  <div className="flex flex-col">
                    <Input
                      id="sale_tax_rate"
                      name="sale_tax_rate"
                      onChange={handleChange}
                      value={values.sale_tax_rate || ""}
                    />
                    {errors.sale_tax_rate ? (
                      <div></div>
                    ) : (
                      // <ErrorMessage
                      //   name="sale_tax_rate"
                      //   component="div"
                      //   className="text-xs text-red-500"
                      // />
                      ""
                    )}
                  </div>
                </div>
                <Button
                  type="submit"
                  size="lg"
                  className="m-auto mr-3 mt-4 h-10 w-fit  p-4"
                  disabled={isSubmitting}
                >
                  <span className="m-2">Next</span> {isLoading && <Spinner />}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
};

import { TicketItemProps, TicketSquareProps } from "../../@types";

const SquareTool = ({ id, props }: TicketItemProps) => {
  const { borderColor, borderWidth, backgroundColor, angle } =
    props as TicketSquareProps;
  return (
    <div
      style={{
        border: `${borderWidth}px solid ${borderColor}`,
        backgroundColor: backgroundColor,
        transform: `rotate(${angle}deg)`,
      }}
      className={`${
        backgroundColor ? `bg-[${backgroundColor}]` : "bg-primary"
      }  h-full w-full  `}
    ></div>
  );
};

export default SquareTool;

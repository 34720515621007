import { useContext, useEffect } from "react";
import { TicketBuilderContext } from "../../context/ticketBuilder.context";
import { sajilniApi } from "../../services";
import useSWR from "swr";
import {
  MergeTag,
  MergeTagGroup,
  TicketTemplate,
  TicketTemplateTranslations,
} from "database/*";
import {
  TicketProps,
  TicketQROrBarcodeProps,
  TicketTextProps,
} from "../../@types";
import { produce } from "immer";
import { TicketItemTypes } from "../../@types/ticketBuilderTypes";

export const useEvent = (eventId?: string) => {
  const fetcher = (url: string) => sajilniApi.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(
    eventId ? `/events/${eventId}` : null,
    fetcher
  );
  return {
    event: data?.event,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
export const useTicket = (ticketId?: string) => {
  const fetcher = (url: string) => sajilniApi.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(
    ticketId ? `/tickets/${ticketId}` : null,
    fetcher
  );
  return {
    ticket: data?.ticket,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export const useMergeTagsGroup = () => {
  const fetcher = (url: string) => sajilniApi.get(url).then((res) => res.data);
  const { data, error } = useSWR("/mergeTag/group", fetcher);
  return {
    mergeTagGroups: data?.mergeTagGroups || [],
    isLoading: !error && !data,
    isError: error,
  };
};
export const useMergeTags = () => {
  const fetcher = (url: string) => sajilniApi.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR("/mergeTag", fetcher);
  return {
    mergeTags: data?.mergeTags || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export const useMegeTageValueReplacer = (
  eventId?: string,
  ticketId?: string,
  templateData?: TicketTemplate & TicketTemplateTranslations,
  dynamicValues?: {
    [key: string]: any;
  }
) => {
  const { setTicketState } = useContext(TicketBuilderContext);
  const { event } = useEvent(eventId);
  const { ticket } = useTicket(ticketId);
  const { mergeTagGroups } = useMergeTagsGroup();
  const { mergeTags } = useMergeTags();

  const getValue = (groupName: string, tagName: string) => {
    switch (groupName) {
      case "Event":
        return event?.[tagName];
      case "Ticket":
        return ticket?.[tagName];
      default:
        return "";
    }
  };

  const replaceFunction = (
    group?: MergeTagGroup,
    tag?: MergeTag,
    mergeTagName?: string
  ) => {
    if ((!group || !tag) && mergeTagName)
      return {
        searchValue: new RegExp(`${mergeTagName}`, "g"),
        replaceValue:
          dynamicValues?.[mergeTagName.replace("{", "").replace("}", "")] || "",
      };
    else
      return {
        searchValue: new RegExp(`{${group?.name}.${tag?.name}}`, "g"),
        replaceValue: getValue(group?.name as string, tag?.name as string),
      };
  };

  useEffect(() => {
    if (templateData && templateData?.variables?.length > 0) {
      let state = JSON.parse(templateData.json as string) as TicketProps[];

      templateData.variables.forEach((variable) => {
        const tag = mergeTags?.find(
          (tag: MergeTag) => tag.id === variable.mergeTagId
        );
        const group = mergeTagGroups?.find(
          (group: MergeTagGroup) => group.id === tag?.mergeTagGroupId
        );

        state = produce(state, (draft) => {
          const element = draft.find((elm) => elm.id === variable.elementId);
          const { searchValue, replaceValue } = replaceFunction(
            group,
            tag,
            variable?.mergeTagName || ""
          );
          switch (element?.type) {
            case TicketItemTypes.text:
              (element.props as TicketTextProps).textValue = (
                element.props as TicketTextProps
              ).textValue.replace(searchValue, replaceValue);
              break;
            case TicketItemTypes.barcode:
              const barcodeElementProps =
                element.props as TicketQROrBarcodeProps;
              barcodeElementProps.value = barcodeElementProps.value.replace(
                searchValue,
                replaceValue
              );
              break;
            case TicketItemTypes.qr:
              const qrElementProps = element.props as TicketQROrBarcodeProps;
              qrElementProps.value = qrElementProps.value.replace(
                searchValue,
                replaceValue
              );
              break;
            default:
              break;
          }
        });
      });
      setTicketState(state);
    } else if (templateData?.json) {
      setTicketState(JSON.parse(templateData.json as string));
    }
  }, [templateData, event, ticket, mergeTags, mergeTagGroups]);
};

"use client";
import React, { useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { useRouter } from "next/navigation";

import { Button, Input } from "@/lib/ui";

type Data = {};
interface FormValues {
  coupon: string;
  percentage: number | null;
  valid_number: number | null;
}
const initialValues: FormValues = {
  coupon: "",
  percentage: null,
  valid_number: null,
};
export const CouponForm = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const data: Data = {
        coupon: values.coupon,
        percentage: values.percentage,
        valid_number: values.valid_number,
      };

      const res = await fetch("/api/coupon", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await res.json();
      setIsLoading(false);

      !isLoading && router.push("/coupon");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main className="lg:pl-72">
      <div className="m-auto flex flex-col gap-2 py-5">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 ">
          <h3 className="bg-g group flex gap-x-3 rounded-md p-2 px-3 pt-4 text-lg font-semibold leading-6 text-indigo-600 ">
            Add Coupon
          </h3>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            //validationSchema={validationSchema}
            validateOnChange={false}
            validateOnSubmit={true}
            validateOnBlur={false}
          >
            {({ handleSubmit, errors, isSubmitting, handleChange, values }) => (
              <Form className=" m-4 flex flex-col" onSubmit={handleSubmit}>
                <div className="flex flex-col gap-2 py-4">
                  <div className="flex flex-col">
                    <Input
                      id="coupon"
                      name="coupon"
                      onChange={handleChange}
                      value={values.coupon}
                    />
                    {errors?.coupon && (
                      <div className="px-4 text-xs text-red-500">
                        {errors.coupon}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <Input
                      id="percentage"
                      name="percentage"
                      onChange={handleChange}
                      value={values.percentage || 0}
                      type="number"
                    />
                    {errors.percentage ? (
                      <div className="px-4 text-xs text-red-500">
                        {errors.percentage}
                      </div>
                    ) : (
                      // <ErrorMessage
                      //   name="percentage"
                      //   component="div"
                      //   className="text-xs text-red-500 px-4"
                      // />
                      ""
                    )}
                  </div>
                  <div className="flex flex-col">
                    <Input
                      id="valid_number"
                      name="valid_number"
                      onChange={handleChange}
                      value={values.valid_number || 0}
                      type="number"
                    />
                    {errors.valid_number ? (
                      <div className="px-4 text-xs text-red-500">
                        {errors.valid_number}
                      </div>
                    ) : (
                      // <ErrorMessage
                      //   name="valid_number"
                      //   component="div"
                      //   className="text-xs text-red-500 px-4"
                      // />
                      ""
                    )}
                  </div>
                </div>
                <Button
                  type="submit"
                  size="lg"
                  className="bottom-20 right-2 m-auto mr-3 h-10 w-fit  p-4 outline outline-1 filter"
                  disabled={isSubmitting}
                >
                  {isLoading ? "Loading..." : "Next"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
};

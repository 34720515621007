"use client";
import { Actions } from "./Actions";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import {
  TicketBuilderContextProvider,
  TicketViewer,
} from "@sajilni/ticket-builder";
import moment from "moment";
import useSWRMutation from "swr/mutation";
import {
  Order,
  Event,
  OrderItem,
  Ticket,
  TicketTemplate,
  FormTemplate,
  EventTranslations,
  Profile,
  OrderStatus,
} from "database";
import { Swipe } from "../../lib/swipe";
import { usePrintHook } from "./hooks";
import { Download } from "lucide-react";
import { Button } from "@/lib/ui";
import { useTranslation } from "@sajilni/localization";
import mustache from "mustache";
interface SuccessOrder extends Order {
  profile?: Profile;
  orderItems: OrderItem &
    {
      ticket: Ticket & {
        formEntity: {
          formTemplate: FormTemplate | null;
        } | null;
        template: TicketTemplate;
      }; // Add the template property to the ticket object
    }[];
}
const Logo =
  "https://3nahrfk4mnfktf1o.public.blob.vercel-storage.com/small-logo-RIkElIrLxlKy0WhXhMqt6F3JwOYI82.png";
const AppleWalletApi = "/api/appleWallet";
const GoogleWalletApi = "/api/success-actions";
export const Success = ({
  order,
  event,
  results,
  eventSlug,
  emailData,
}: {
  order: SuccessOrder;
  event: Event & EventTranslations;
  results: any;
  eventSlug: string;
  emailData: any;
}) => {
  const data = {
    name: event.name,
    description: event.description,
    startDate: moment(event.startDate).format("YYYY-MM-DD"),
    endDate: moment(event.endDate).format("YYYY-MM-DD"),
    // startTime: "23:30", // moment(event.startDate).format("HH:MM"),
    // endTime: moment(event.endDate).format("HH:MM"),
    location: event.venue
      ? event.venue[0]?.countryName + "/" + event.venue[0]?.cityName
      : "",
  };
  const router = useRouter();
  useEffect(() => {
    const handlePopState = () => {
      router.replace(`/checkout/${eventSlug}/ticket-selection`);
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [router]);
  const { prinBadge } = usePrintHook(
    emailData
      ? mustache.render(emailData, {
          order,
          event,
          user: order.profile,
          ticket: order.orderItems.map((item: any) => item?.ticket),
        })
      : ""
  );
  const { t } = useTranslation("sajilni2.0");
  const generateUniqueId = () => {
    const uniqueId = `${Date.now()}`;
    return uniqueId;
  };

  const [googleWalletResponse, setGoogleWalletResponse] = useState(undefined);
  async function sentGoogleWallet(url: string, { arg }: { arg: any }) {
    let error, data;
    await fetch(url, {
      method: "POST",
      body: JSON.stringify(arg),
    }).then(async (res) => {
      const result = await res.json();
      if (result) {
        setGoogleWalletResponse(result?.result);
        return (data = result);
      } else if (result.error) return (error = result.error);
    });
    return { data, error };
  }
  const { trigger: trigger } = useSWRMutation(
    GoogleWalletApi,
    sentGoogleWallet
  );
  async function sentAppleWallet(url: string, { arg }: { arg: any }) {
    let error, data;
    await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(arg),
    }).then(async (res) => {
      const result = await res.json();
      if (result) {
        return (data = result);
      } else if (result.error) return (error = result.error);
    });
    return { data, error };
  }
  const { trigger: triggerAppleWallet } = useSWRMutation(
    AppleWalletApi,
    sentAppleWallet
  );
  const appleWallet = async (data: any) => {
    triggerAppleWallet({
      qrText: data?.orderItemId,
      logoText: data?.ticketName,
      description: data?.eventName,
      thumbnail: data?.eventImage,
      logo: Logo,
      primary: {
        value: data?.eventName,
        label: t("Event Name"),
      },
      secondary: [
        {
          value: event?.venue?.[0]?.address,
          label: t("Event Address"),
        },
      ],
      auxiliary: [
        {
          value: data?.eventStartDate,
          label: t("Date"),
        },
        {
          value: data?.eventStartTime,
          label: t("Doors Open"),
        },
      ],
      backgroundColor: "#FFFFFF",
      textColor: "#000000",
    });
  };
  useEffect(() => {
    if (googleWalletResponse != undefined) {
      window.open(googleWalletResponse, "_blank");
    }
  }, [googleWalletResponse]);
  const googleWallet = async (data: any) => {
    trigger({
      uniqueId: generateUniqueId(),
      eventName: data?.eventName,
      eventDescription: data?.eventDescription,
      orderItemId: data?.orderItemId,
      ticketName: data?.ticketName,
      eventStartDate: data?.eventStartDate,
      eventImage: data?.eventImage,
      eventAddress: event?.venue?.[0]?.address,
    });
  };
  return (
    <main className="flex flex-col items-center gap-2 py-5">
      <h3 className="text-lg font-bold text-gray-950">
        {t("orderPlacedSuccessfully")}
      </h3>
      {order?.status == OrderStatus.WAITING_APPROVAL ? (
        <>
          {t("your request has been sent to the event organizer for approval")}
        </>
      ) : (
        <div className="flex flex-col items-center space-y-4">
          <TicketBuilderContextProvider>
            <Swipe
              slides={order?.orderItems.map((item: any) => (
                <TicketViewer
                  key={item.id}
                  templateData={item?.ticket?.template}
                  ticketId={item.ticket.id}
                  eventId={event.id}
                  orderItemData={item}
                  order={order}
                  dynamicValues={
                    results.find((ele: any) => {
                      return ele?.formTemplate?.formEntities?.find(
                        (f: any) => item?.ticketId === f.entityID
                      );
                    })?.surveyResult
                  }
                  viewerClassName="w-full flex justify-center"
                />
              ))}
              containerClass="min-h-[10rem] w-full "
              infinite={false}
            >
              <div></div>
            </Swipe>
          </TicketBuilderContextProvider>
          <span className="text-base text-gray-400">
            {t("confirmationEmail")}
          </span>
          <span className="text-base text-[#19AE67]">
            {order?.profile?.email}
          </span>
          <div className="flex flex-row items-center justify-center gap-2">
            {emailData && (
              <Button
                variant="solid"
                className="gap-2 whitespace-nowrap bg-[#19AE67] px-5 py-3 text-white"
                size="sm"
                onClick={() => prinBadge()}
              >
                <Download className="h-5 w-5" />
                Download
              </Button>
            )}
            <Actions
              appleWallet={appleWallet}
              order={order}
              googleWallet={googleWallet}
              event={event}
            />
          </div>
        </div>
      )}
    </main>
  );
};

"use client";
import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "../Shared/Spinner";
import Link from "next/link";
import { TrashIcon } from "@heroicons/react/24/outline";

export const CouponComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dataRef = useRef<any>(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const res = await fetch("/api/coupon", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      dataRef.current = data;
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const deleteItem = async (ele: any) => {
    try {
      setIsLoading(true);

      await fetch(`/api/coupon?id=${ele.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      dataRef.current.coupons = dataRef.current.coupons.filter(
        (item: any) => item.id !== ele.id
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <main>
      <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 ">
        <Link
          href="coupon/add"
          className="hover:text-green-default bottom-20 right-2 m-auto mr-3 mt-4 flex h-10 w-fit items-center rounded-3xl bg-gradient-to-l p-4 outline outline-1 filter transition delay-100 duration-300 ease-in-out"
        >
          Add Coupon
        </Link>
        <div>
          {isLoading && <Spinner />}
          <ul className="flex max-w-[70%] flex-col gap-2 py-3 ">
            {dataRef?.current?.coupons &&
              dataRef.current.coupons.map((ele: any) => (
                <li
                  key={ele.id}
                  className="flex items-center justify-between gap-3"
                >
                  <span className="px-3">{ele.coupon}</span>
                  <span>{ele.percentage}%</span>
                  <span>{ele.valid_number}</span>

                  <TrashIcon
                    className={
                      "h-4 w-4 cursor-pointer justify-self-end text-gray-700 hover:text-indigo-600"
                    }
                    aria-hidden="true"
                    onClick={() => deleteItem(ele)}
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </main>
  );
};

export interface TicketProps {
  id: string;
  type: TicketItemTypes;
  initPos: PositionProps;
  delta?: PositionProps;
  props:
    | TicketTextProps
    | TicketSquareProps
    | TicketImageProps
    | TicketQROrBarcodeProps
    | TicketContainerProps;
}

export interface PositionProps {
  x: number;
  y: number;
  // scaleX: number;
  // scaleY: number;
}

export enum TicketItemTypes {
  "container" = "container",
  "image" = "image",
  "text" = "text",
  "qr" = "qr",
  "barcode" = "barcode",
  "square" = "square",
}

export interface DragItem {
  translate?: {
    x: number;
    y: number;
    // scaleX: number;
    // scaleY: number;
  };
}

export interface TicketTextProps {
  textValue: string;
  format: string;
  fontFamily: string;
  fontSize: number;
  fontWeight: string;
  fontStyle: FontStyle[];
  textAlign: "left" | "center" | "right" | "justify";
  color: string;
  backgroundColor: string;
  colorStroke: string;
  letterSpacing: number;
  wordSpacing: number;
  angle: number;
}

export type FontStyle = "bold" | "italic" | "underline" | "line-through";

export interface TicketSquareProps {
  width: number;
  height: number;
  backgroundColor: string;
  borderWidth: number;
  borderColor: string;
  angle: number;
}

export interface TicketImageProps {
  width: number;
  height: number;
  src: string;
  borderColor: string;
  borderWidth: number;
  angle: number;
}

export interface TicketQROrBarcodeProps {
  width: number;
  height: number;
  value: string;
  borderWidth: number;
  borderColor: string;
  type:
    | "qr"
    | "CODE128"
    | "CODE39"
    | "EAN13"
    | "ITF14"
    | "MSI"
    | "pharmacode"
    | "codabar"
    | "upc";
  angle: number;
}

export interface TicketContainerProps {
  backgroundSrc: string;
  layoutOrientation: "portrait" | "landscape";
  width: number;
  height: number;
  layoutFormat: "Custom" | "A4" | "A5" | "A6" | "A7" | "A8";
  isDefault: boolean;
  POS: boolean;
}

export interface TicketItemProps {
  id: string;
  props?: TicketProps["props"];
}

import { useState, type FC, useContext, useEffect } from "react";
import { Draggable } from "../lib/@dnd-kit";
import { DragItem, TicketItemTypes } from "../@types/ticketBuilderTypes";
import {
  TextTool,
  SquareTool,
  ImageTool,
  QRcodeTool,
  BarcodeTool,
} from "./Tools";
import { Resizable } from "../lib/react-resizable";
import { TicketBuilderContext } from "../context/ticketBuilder.context";
import { getRandomStringId } from "../utils/helpers/getRandomId";
import {
  ArrowDownWideNarrow,
  ArrowUpWideNarrow,
  Trash2,
  Copy,
} from "lucide-react";
import { Button } from "../lib/ui";
import { produce } from "immer";

export interface InnerDragComponentProps extends DragItem {
  id: string;
  type: TicketItemTypes;
  defaultSize?: { width: number; height: number };
  disabled?: boolean;
}

export const InnerDragComponent: FC<InnerDragComponentProps> = ({
  id,
  type,
  translate,
  disabled,
}) => {
  const { ticketState, setSelectedItemId, setTicketState, selectedItemId } =
    useContext(TicketBuilderContext);

  const [props, setProps] = useState(
    ticketState.find((item) => item.id === id)?.props
  );

  const EditButtonsData = [
    {
      icon: ArrowUpWideNarrow,
      onClick: () => selectedItemId && moveItem(selectedItemId, "front"),
    },
    {
      icon: ArrowDownWideNarrow,
      onClick: () => selectedItemId && moveItem(selectedItemId, "back"),
    },
    {
      icon: Trash2,
      onClick: () => selectedItemId && deleteItem(selectedItemId),
    },
    {
      icon: Copy,
      onClick: () => selectedItemId && copyItem(selectedItemId),
    },
  ];

  useEffect(() => {
    if (ticketState) {
      setProps(ticketState.find((item) => item.id === id)?.props);
    }
  }, [ticketState]);

  const moveItem = (id: string, place: "front" | "back") => {
    // move item to last index in the array if place is front
    // move item to first index in the array if place is back
    // using produce to mutate the array
    const updatedElements = produce(
      ticketState,
      (
        draft: {
          id: string;
          type: string | number;
          initPos: { x: number; y: number };
          delta: { x: number; y: number };
          props: any;
        }[]
      ) => {
        const item = draft.find((item) => item.id === id);
        if (item) {
          const index = draft.indexOf(item);
          if (place === "front") {
            draft.push(draft.splice(index, 1)[0]);
          } else if (place === "back") {
            draft.unshift(draft.splice(index, 1)[0]);
          }
        }
      }
    );
    setTicketState(updatedElements);
  };

  const deleteItem = (id: string) => {
    setTicketState(ticketState.filter((item) => item.id !== id));
    setSelectedItemId(null);
  };

  const copyItem = (id: string) => {
    const item = ticketState.find((item) => item.id === id);
    if (item) {
      setTicketState([
        ...ticketState,
        { ...item, id: getRandomStringId(ticketState.map((item) => item.id)) },
      ]);
    }
  };

  const renderComponent = (type: TicketItemTypes) => {
    switch (type) {
      case "text":
        return <TextTool id={id} props={props} />;
      case "square":
        return <SquareTool id={id} props={props} />;
      case "image":
        return <ImageTool id={id} props={props} />;
      case "qr":
        return <QRcodeTool id={id} props={props} />;
      case "barcode":
        return <BarcodeTool id={id} props={props} />;

      default:
        return <div>item</div>;
    }
  };

  return (
    <Draggable
      id={id}
      pos={translate ? { ...translate } : undefined}
      className="  h-fit w-fit"
      disabled={disabled}
    >
      <Resizable
        className="relative"
        enable={disabled ? {} : undefined}
        size={{
          // @ts-ignore
          width: props.width,
          // @ts-ignore
          height: props.height,
        }}
        onResizeStart={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onResizeStop={(e, direction, ref, d) => {
          setTicketState(
            ticketState.map((item) => {
              // @ts-ignore
              if (item.id === id && item.props.width && item.props.height) {
                return {
                  ...item,
                  props: {
                    ...item.props,
                    // @ts-ignore
                    width: item.props.width + d.width,
                    // @ts-ignore
                    height: item.props.height + d.height,
                  },
                };
              }
              return item;
            })
          );
        }}
      >
        {renderComponent(type)}
        {selectedItemId === id && (
          <div className=" absolute -bottom-20 left-0 right-0 z-50 flex h-full w-full items-center space-x-2">
            {EditButtonsData.map((item, index) => (
              <Button
                key={index}
                className="h-8 w-8 rounded-full bg-gray-100 p-2 text-gray-900 hover:bg-gray-200"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  item.onClick();
                }}
              >
                <item.icon size={16} />
              </Button>
            ))}
          </div>
        )}
      </Resizable>
    </Draggable>
  );
};

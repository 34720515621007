import React from "react";
import Link from "next/link";

export const SettingsComponent = () => {
  const settings = [
    {
      link: "currency",
      value: "Currency",
    },
    {
      link: "privacy",
      value: "Privacy",
    },
  ];
  return (
    <main>
      <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 ">
        <h1 className="m-2 ">Settings</h1>
        <ul className="mt-4 flex min-h-[100px] items-center justify-evenly space-y-2">
          {settings.map((ele: any, index: number) => (
            <li
              key={index}
              className="m-auto min-h-[100px] min-w-[100px] border border-slate-400 text-center shadow-md hover:border-indigo-600 "
            >
              <Link href={`settings/${ele.link}`}>
                <span className="flex h-[100px] items-center justify-center">
                  {ele.value}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </main>
  );
};

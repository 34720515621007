import { TicketWrapperComponent } from './TicketWrapperComponent';
import { useContext, useEffect } from 'react';
import { TicketBuilderContext } from '../context/ticketBuilder.context';

import {
  OrderItem,
  TicketTemplate,
  TicketTemplateTranslations,
} from 'database';
import { TicketContainerProps } from '../@types';
import { InnerDragComponent } from './InnerDragComponent';
import { useMegeTageValueReplacer } from '../utils/hooks/useMegeTageValueReplacer';
import { cn } from '@sajilni/utils';
import { QRGenerator } from '../lib/qrcode.react';
export const TicketViewer = ({
  templateData,
  eventId,
  ticketId,
  dynamicValues,
  viewerClassName,
  order,
  orderItemData,
}: {
  templateData?: TicketTemplate & TicketTemplateTranslations;
  eventId?: string;
  ticketId?: string;
  dynamicValues?: {
    [key: string]: any;
  };
  viewerClassName?: string;
  order?: any;
  orderItemData?: OrderItem;
}) => {
  const { ticketState } = useContext(TicketBuilderContext);

  const props = ticketState.find((item) => item.id === 'container')
    ?.props as TicketContainerProps;

  const variablesReplacer = useMegeTageValueReplacer(
    eventId,
    ticketId,
    templateData,
    dynamicValues
  );

  return (
    <div
      className={cn(
        ' relative !h-full  !w-full rounded-xl bg-white',
        viewerClassName
      )}
    >
      <div
        className="flex flex-col items-center justify-start gap-4 rounded-xl p-3"
        style={{
          width: props.width,
          height: props.height,
          backgroundImage: `url(${props.backgroundSrc})`,
        }}
      >
        <span className="text-base text-gray-400">Your order number</span>

        <span className=" text-5xl text-gray-900">{order?.number}</span>

        <span className="text-base text-[#19AE67]">
          {dynamicValues?.['ticket.name'] || order?.profile?.name}
        </span>

        {ticketState
          .filter((item) => item.type !== 'container')
          .map((item) => {
            return (
              <div key={item.id} className="h-fit w-fit">
                <InnerDragComponent
                  id={item.id}
                  type={item.type}
                  translate={
                    ticketState.find((ele) => ele.id === item.id)?.delta
                  }
                  disabled={true}
                />
              </div>
            );
          })}
        <QRGenerator
          value={orderItemData?.id || order.id}
          id={orderItemData?.id || order.id}
        />
      </div>
    </div>
  );
};
